import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

function CustomCampaignPage() {
  const [CustomCampaigns, setCustomCampaigns] = useState([]);

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/campaigns/getAllCampaign`
      );
      setCustomCampaigns(response.data)
    }
    catch (err) {
      console.log(err)
    }
  }
  const handleDeleteCampaign = async (campaignId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/campaigns/delete/${campaignId}`);
      getInitialData()
      Swal.fire({
        title: "Deleted",
        text: "Campaign deleted successfully.",
        icon: "success",
      });
    }
    catch (err) {
      console.log(err)
    }
  }

  const CustomCampaignsTableData = {
    columns: [
      { Header: "Technology", accessor: "technology", align: "left" },
      { Header: "Normal course", accessor: "normalCourse", align: "left" },
      { Header: "Batch course", accessor: "batchCourse", align: "left" },
      { Header: "Exam", accessor: "selectedExam", align: "center" },
      { Header: "Support", accessor: "supportElectronic", align: "center" },
      { Header: "User", accessor: "fromUser", align: "center" },
      { Header: "Price", accessor: "price", align: "center" },

      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: CustomCampaigns.map((CustomCampaign) => ({
      technology: CustomCampaign?.technology?.name,
      normalCourse: CustomCampaign?.normalCourse?.title,
      batchCourse: CustomCampaign?.batchCourse?.title,
      selectedExam: CustomCampaign?.selectedExam?.title,
      supportElectronic: CustomCampaign?.supportElectronic?.title,
      fromUser: CustomCampaign?.fromUser?.fullName,
      price: CustomCampaign?.price,

      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Delete Campaign">
            <IconButton color="error" onClick={() => handleDeleteCampaign(CustomCampaign._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Custom Campaigns
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={CustomCampaignsTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CustomCampaignPage;
