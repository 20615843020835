import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddVoucher() {
  const navigate = useNavigate();
  const [voucherData, setVoucherData] = useState({
    name: "",
    description: "",
    fromExam: "",
    forUser: "",
    isActive: false,
    isValid: false,
    expiredDate: "",
  });

  const [errors, setErrors] = useState({});

  const [exams, setExams] = useState([]);
  const [users, setUsers] = useState([]);

  const updateVoucherData = (field, value) => {
    setVoucherData((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    try {
      const examResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/V2/exame/all-exame`,
      );
      const userResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/V2/user/usersall`,
      );

      setExams(examResponse.data.data);
      setUsers(userResponse.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    if (
      !voucherData.name ||
      !voucherData.description ||
      !voucherData.fromExam ||
      !voucherData.forUser
    ) {
      setErrors({
        name: "Name is required",
        description: "Description is required",
        fromExam: "Exam is required",
        forUser: "User is required",
      });
      return;
    }

    const voucherFormData = {
      name: voucherData.name,
      description: voucherData.description,
      fromExam: voucherData.fromExam,
      forUser: voucherData.forUser,
      expiredDate: voucherData.expiredDate,

      isActive: voucherData.isActive,
      isValid: voucherData.isValid,
    };

    try {
      const UserID = sessionStorage.getItem("user")
      const Credentials = sessionStorage.getItem("token")
      const Token = JSON.parse(Credentials)

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v2/voucher/add-voucher/${UserID}/${Token.iv}`,
        voucherFormData,
        {
          headers: {
            Authorization: `Bearer ${Token.content}`,
            "Content-Type": "application/json"
          }
        }
      );

      Swal.fire({
        title: "Success!",
        text: "The voucher has been added successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/vouchers/vouchers-page");
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to add the voucher.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit Voucher
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This information will describe the new voucher.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Voucher Details
                </MDTypography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Name"
                      value={voucherData.name}
                      onChange={(e) =>
                        updateVoucherData("name", e.target.value)
                      }
                    />
                    {errors.name && (
                      <MDTypography color="error">{errors.name}</MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Description"
                      value={voucherData.description}
                      onChange={(e) =>
                        updateVoucherData("description", e.target.value)
                      }
                    />
                    {errors.description && (
                      <MDTypography color="error">
                        {errors.description}
                      </MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel>Exams</InputLabel>
                      <Select
                        value={voucherData.fromExam}
                        onChange={(e) =>
                          updateVoucherData("fromExam", e.target.value)
                        }
                      >
                        {exams?.map((exam) => (
                          <MenuItem key={exam._id} value={exam._id}>
                            {exam?.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.fromExam && (
                      <MDTypography color="error">
                        {errors.fromExam}
                      </MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel>Users</InputLabel>
                      <Select
                        value={voucherData.forUser}
                        onChange={(e) =>
                          updateVoucherData("forUser", e.target.value)
                        }
                      >
                        {users?.map((users) => (
                          <MenuItem key={users._id} value={users._id}>
                            {users?.fullName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.fromExam && (
                      <MDTypography color="error">
                        {errors.fromExam}
                      </MDTypography>
                    )}
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="User"
                      value={voucherData.forUser}
                      onChange={(e) =>
                        updateVoucherData("forUser", e.target.value)
                      }
                    />
                    {errors.forUser && (
                      <MDTypography color="error">
                        {errors.forUser}
                      </MDTypography>
                    )}
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="date"
                      label="Expiration Date"
                      value={voucherData.expiredDate}
                      onChange={(e) =>
                        updateVoucherData("expiredDate", e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      IsActive
                    </MDTypography>
                    <Switch
                      checked={voucherData.isActive}
                      onChange={(e) =>
                        updateVoucherData("isActive", e.target.checked)
                      }
                      color="primary"
                    />
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      isValid
                    </MDTypography>
                    <Switch
                      checked={voucherData.isValid}
                      onChange={(e) =>
                        updateVoucherData("isValid", e.target.checked)
                      }
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Publish
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddVoucher;
