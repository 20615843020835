import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";

function UsersLists() {
  const navigate = useNavigate();

  const [usersList, setUsersList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsersList, setFilteredUsersList] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V2/user/usersall`
        );
        console.log("API Response:", response.data);
        setUsersList(response.data);
        setFilteredUsersList(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
        Swal.fire({
          title: "Error",
          text: "Failed to load users. Please try again later.",
          icon: "error",
        });
      }
    };
  
    fetchUsers();
  }, []);
  

  const deleteUser = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/V2/user/users/${id}`
      );
      setUsersList((prev) => prev.filter((user) => user._id !== id));
      setFilteredUsersList((prev) => prev.filter((user) => user._id !== id));
      Swal.fire({
        title: "Deleted",
        text: "User deleted successfully.",
        icon: "success",
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to delete user. Please try again.",
        icon: "error",
      });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query === "") {
      setFilteredUsersList(usersList);
    } else {
      const filtered = usersList.filter((user) =>
        user.fullName.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredUsersList(filtered);
    }
  };

  const UsersListTableData = {
    columns: [
      { Header: "Full Name", accessor: "fullName", align: "left" },
      { Header: "Phone", accessor: "phone", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Voucher", accessor: "voucher", align: "left" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: filteredUsersList.map((user) => ({
      fullName: user.fullName,
      phone: user.phone,
      email: user.email,
      voucher: user.voucher && user.voucher.length > 0
        ? user.voucher.map(
            (v) =>
              `Code: ${v.code}, Discount: ${v.discount}%, Expiration: ${new Date(
                v.expirationDate
              ).toLocaleDateString()}`
          ).join("; ")
        : "No Vouchers",
      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Edit User">
            <IconButton
              color="info"
              onClick={() =>
                navigate(`/dashboard/userList/edit-userList/${user._id}`)
              }
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete User">
            <IconButton color="error" onClick={() => deleteUser(user._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          Users List
        </MDTypography>
      </MDBox>
      <MDBox mt={2} mb={2} mx={2}>
        <TextField
          value={searchQuery}
          onChange={handleSearch}
          label="Search by Name"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: "1rem" }}
        />
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={UsersListTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UsersLists;
