import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Grid, Card, Box, Typography, Button, Input } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Select from "react-select";
import ReactQuill from "react-quill";

function EditePortfolio() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [portfolioData, setPortfolioData] = useState({
    title: "",
    mainDescription: "",
    plainMainDescription: "",
    categories: [],
    mainlmg: null,
  });

  const [currentImg, setCurrentImg] = useState(null);

  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});

  const updatePortfolioData = (field, value) => {
    setPortfolioData((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/category/categories`,
        );
        setCategories(categoryResponse.data.data || []);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/portfolio/all-portfolios/${id}`,
        );

        const data = response.data;

        // Convert the current image URL to a File object
        let mainlmgFile = null;
        if (data.mainlmg?.url) {
          const imageResponse = await fetch(data.mainlmg.url);
          const blob = await imageResponse.blob();
          mainlmgFile = new File([blob], data.mainlmg.filename, {
            type: data.mainlmg.type,
          });
        }

        setPortfolioData({
          title: data.title || "",
          mainDescription: data.mainDescription || "",
          categories: data.categories || "",
          mainlmg: mainlmgFile,
        });

        setCurrentImg(data.mainlmg?.url || "");
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Failed to fetch category data.",
          icon: "error",
          confirmButtonText: "OK",
        });

        console.error("Error fetching category data:", error);
      }
    };

    fetchCategoryData();
  }, [id]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPortfolioData((prevState) => ({
        ...prevState,
        mainlmg: file,
      }));
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", portfolioData.title);
    formData.append("mainDescription", portfolioData.mainDescription);

    if (portfolioData.categories && portfolioData.categories.length > 0) {
      formData.append("categories", JSON.stringify(portfolioData.categories));
    } else {
      console.error("Categories are empty!");
      return;
    }

    if (portfolioData.mainlmg) {
      formData.append("mainlmg", portfolioData.mainlmg);
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/portfolio/all-portfolios/${id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      );
      Swal.fire({
        title: "Success!",
        text: "Portfolio updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/4-portfolio/portfolio-page");
      console.log("Response:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to add portfolio.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
  };

  const handleDescriptionChange = (value, delta, source, editor) => {
    updatePortfolioData("mainDescription", value); // Keep HTML in state
    const plainText = editor.getText(); // Extract plain text (no HTML)
    updatePortfolioData("plainMainDescription", plainText.trim()); // Store plain text
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edite Portfolio
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Details about the portfolio.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Portfolio Details
                </MDTypography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <Input
                      fullWidth
                      placeholder="Title"
                      value={portfolioData.title}
                      onChange={(e) =>
                        updatePortfolioData("title", e.target.value)
                      }
                    />
                    {errors.title && (
                      <MDTypography color="error">{errors.title}</MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {/* <Input
                      fullWidth
                      placeholder="Main Description"
                      value={portfolioData.mainDescription}
                      onChange={(e) => updatePortfolioData("mainDescription", e.target.value)}
                    />
                    {errors.mainDescription && (
                      <MDTypography color="error">{errors.mainDescription}</MDTypography>
                    )} */}
                    <ReactQuill
                      value={portfolioData.mainDescription}
                      onChange={handleDescriptionChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Categories
                    </MDTypography>
                    {Array.isArray(categories) && (
                      <Select
                        options={categories.map((item) => ({
                          label: item.name,
                          value: item._id,
                        }))}
                        value={
                          portfolioData.categories
                            ? categories
                                .filter((cat) =>
                                  portfolioData.categories.includes(cat._id),
                                )
                                .map((cat) => ({
                                  label: cat.name,
                                  value: cat._id,
                                }))
                            : []
                        }
                        onChange={(selectedOptions) =>
                          updatePortfolioData(
                            "categories",
                            selectedOptions
                              ? selectedOptions.map((opt) => opt.value)
                              : [],
                          )
                        }
                        isMulti
                        placeholder="Select Categories"
                      />
                    )}
                    {errors.categories && (
                      <MDTypography color="error">
                        {errors.categories}
                      </MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="light"
                      color="textSecondary"
                      mb={1}
                    >
                      Upload Image
                    </Typography>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="file-input"
                    />
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      border="2px dashed"
                      borderColor="grey.400"
                      bgcolor="grey.100"
                      borderRadius="8px"
                      py={4}
                      sx={{
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "grey.200" },
                        transition: "background-color 0.3s ease-in-out",
                      }}
                      onClick={() => document.getElementById("file-input").click()} // Trigger file input on click
                    >
                      {!portfolioData.mainlmg && !currentImg && (
                        <>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="textSecondary"
                            mb={1}
                          >
                            Drag & drop an image here, or
                          </MDTypography>
                          <MDButton
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={() => document.getElementById("file-input").click()}
                          >
                            Browse Files
                          </MDButton>
                        </>
                      )}
                      {portfolioData.mainlmg || currentImg ? (
                        <img
                          src={
                            portfolioData.mainlmg instanceof File
                              ? URL.createObjectURL(portfolioData.mainlmg)
                              : currentImg
                          }
                          alt="Portfolio"
                          style={{
                            width: "100%",
                            maxWidth: "150px",
                            marginTop: "10px",
                            borderRadius: "8px",
                            cursor: "pointer", // Add pointer cursor for better UX
                          }}
                          onClick={() => document.getElementById("file-input").click()} // Trigger file input on image click
                        />
                      ) : null}
                    </MDBox>
                    {errors.mainlmg && <MDTypography color="error">{errors.mainlmg}</MDTypography>}
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Publish
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditePortfolio;
