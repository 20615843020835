import React from "react";
import { Route, Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const PrivateRoute = ({ element, ...rest }) => {
  const user = sessionStorage.getItem("user")
  const token = sessionStorage.getItem("token")

  return token && user ? element : <Navigate to="/authentication/sign-in/basic" />;
};

PrivateRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default PrivateRoute;
