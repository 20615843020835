import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Swal from "sweetalert2";
import { MenuItem, TextField, Chip, Box, Typography } from "@mui/material";
import { List, ListItem } from "@mui/material";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function EditCourse() {
  const [courseData, setCourseData] = useState({
    title: "",
    description: "",
    categories: [],
    technologies: [],
    prerequisites: [],
    mainlmg: null,
    type: "normal",
    duration: 1,
    isActive: true,
    inSlider: true,
    onSiteRemote: "empty",
    price: 0,
    sumOfSite: 0,
    language: "English",
    reservedPlaces: 0,
    copon: [],
    level: "Beginner",
    objectifs: [],
    lectures: 1,
    canPassExam: [],
    supports: [],

    // onSite: "",
    // remote: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");

  const [allCategories, setAllCategories] = useState([]);
  const [allTechnologies, setAllTechnologies] = useState([]);
  const [allSupports, setAllSupports] = useState([]);
  const [allCopons, setAllCopons] = useState([]);
  const [allCanPassExams, setAllCanPassExams] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setCourseData((prevData) => ({
        ...prevData,
        mainlmg: file,
      }));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  const fetchData = async (endpoint, setter, labelKey, responseKey) => {
    try {
      const response = await axios.get(endpoint);
      const dataToFormat = response.data[responseKey] || [];
      const formattedData = dataToFormat.map((item) => ({
        value: item._id,
        label: item[labelKey],
      }));
      setter(formattedData);
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
    }
  };
  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API_URL}/api/v2/category/categories`,
      setAllCategories,
      "name",
      "data",
    );
    fetchData(
      `${process.env.REACT_APP_API_URL}/api/v2/technology/technologies`,
      setAllTechnologies,
      "name",
      "data",
    );
    fetchData(
      `${process.env.REACT_APP_API_URL}/api/V2/slectronicsupport/all`,
      setAllSupports,
      "title",
      "electronicSupports",
    );
    fetchData(
      `${process.env.REACT_APP_API_URL}/api/v2/coupon/coupons`,
      setAllCopons,
      "code",
      "data",
    );
    fetchData(
      `${process.env.REACT_APP_API_URL}/api/V2/exame/all-exame`,
      setAllCanPassExams,
      "title",
      "data",
    );

    const fetchCourseData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V2/course/all-course/${id}`,
        );
        const course = response.data;
        if (course) {
          const formatOptions = (items, labelKey) =>
            items.map((item) => ({ value: item._id, label: item[labelKey] }));
          setCourseData({
            ...courseData,
            title: course.title,
            description: course.description || "",
            price: course.price || "",
            reservedPlaces: course.reservedPlaces || "",
            duration: course.duration || "",
            language: course.language || "",
            sumOfSite: course.sumOfSite || "",
            isActive: course.isActive || false,
            inSlider: course.inSlider || false,
            mainlmg: course.mainlmg?.url || null,
            prerequisites: course.prerequisites || [],
            objectifs: course.objectifs || [],
            level: course.level || "",
            lectures: course.lectures || "",
            type: course.type || "",
            onSiteRemote: course.onSiteRemote || "empty",
            technologies: formatOptions(course.technologies, "name"),
            categories: formatOptions(course.categories, "name"),
            copon: formatOptions(course.copon, "code"),
            supports: formatOptions(course.supports, "title"),
            canPassExam: formatOptions(course.canPassExam, "title"),

            // onSite: course.onSite || "",
            // remote: course.remote || "",
          });
        }

        const languagesNameOptionsResponse = [];
        const languagesResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V1/languages`,
        );
        languagesResponse.data.data.map((el) => {
          languagesNameOptionsResponse.push({ label: el.name, value: el.name });
        });
        setAvailableLanguages(languagesNameOptionsResponse);
      } catch (error) {
        console.error("Error fetching course data:", error.message);
      }
    };

    fetchCourseData();
  }, [id]);

  const updateCourseData = (field, value) => {
    setCourseData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(courseData)) {
        if (
          key === "technologies" ||
          key === "categories" ||
          key === "supports" ||
          key === "copon" ||
          key === "canPassExam"
        ) {
          const valuesToSend = value.map((item) => item.value);
          valuesToSend.forEach((val) => formData.append(`${key}[]`, val));
        } else if (key !== "mainlmg" && key !== "originalImage") {
          formData.append(key, value);
        }
      }

      if (courseData.mainlmg && typeof courseData.mainlmg !== "string") {
        formData.append("mainlmg", courseData.mainlmg);
      } else if (courseData.originalImage && !courseData.mainlmg) {
        formData.append("mainlmg", "unchanged");
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/course/all-course/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Course updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/dashboard/3-courses/courses-page");
        });
      }
    } catch (error) {
      console.error(
        "Error updating course:",
        error.response?.data || error.message,
      );
      const errorMessage =
        error.response?.data?.message ||
        "Failed to update course. Please try again.";
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit Course
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Modify the details of your Course below.
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h5">Edit Course</MDTypography>
            <MDBox mt={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Title"
                    value={courseData.title}
                    onChange={(e) => updateCourseData("title", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Description
                  </MDTypography>
                  <ReactQuill
                    value={courseData.description}
                    onChange={(value) => updateCourseData("description", value)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    label="Type"
                    value={courseData.type}
                    onChange={(e) => updateCourseData("type", e.target.value)}
                    select
                  >
                    <MenuItem value="normal">Normal</MenuItem>
                    <MenuItem value="batch">Batch</MenuItem>
                  </FormField>
                </Grid>
                {/* <Grid item xs={12} sm={3}>
                  <FormField
                    label="On Site"
                    value={courseData.onSite}
                    onChange={(e) => updateCourseData("onSite", e.target.value)}
                    select
                  >
                    <MenuItem value="empty">Empty</MenuItem>
                    <MenuItem value="personVue">PersonVue</MenuItem>
                  </FormField>
                </Grid> */}
                {/* <Grid item xs={12} sm={3}>
                  <FormField
                    type="text"
                    label="Remote"
                    value={courseData.remote}
                    onChange={(e) => updateCourseData("remote", e.target.value)}
                    select
                  >
                    <MenuItem value="onVue">OnVue</MenuItem>
                    <MenuItem value="home">Home</MenuItem>
                  </FormField>
                </Grid> */}

                <Grid item xs={12} sm={3}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    OnSite / Remote
                  </MDTypography>
                  <Select
                    options={[
                      { label: "Empty", value: "empty" },
                      { label: "on Site", value: "onSite" },
                      { label: "Remote", value: "remote" },
                    ]}
                    value={{
                      label: courseData.onSiteRemote,
                      value: courseData.onSiteRemote,
                    }}
                    onChange={(selectedOption) =>
                      updateCourseData("onSiteRemote", selectedOption.value)
                    }
                    placeholder="Select on Site / Remote Option"
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormField
                    type="text"
                    label="Language"
                    value={courseData.language}
                    onChange={(e) =>
                      updateCourseData("language", e.target.value)
                    }
                    select
                  >
                    {availableLanguages.map((el) => (
                      <MenuItem value={el.value}>{el.label}</MenuItem>
                    ))}
                  </FormField>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormField
                    label="Level"
                    value={courseData.level}
                    onChange={(e) => updateCourseData("level", e.target.value)}
                    select
                  >
                    <MenuItem value="Beginner">Beginner</MenuItem>
                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                    <MenuItem value="Professional">Professional</MenuItem>
                    <MenuItem value="Expert">Expert</MenuItem>
                    <MenuItem value="Master">Master</MenuItem>
                  </FormField>
                </Grid>
                {/* Technologies */}
                <Grid item xs={12} sm={3}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Technologies
                  </MDTypography>
                  <Select
                    isMulti
                    options={allTechnologies}
                    value={courseData.technologies}
                    onChange={(selectedOptions) =>
                      updateCourseData("technologies", selectedOptions)
                    }
                  />
                </Grid>
                {/* Categories */}
                <Grid item xs={12} sm={3}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Categories
                  </MDTypography>
                  <Select
                    isMulti
                    options={allCategories}
                    value={courseData.categories}
                    onChange={(selectedOptions) =>
                      updateCourseData("categories", selectedOptions)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Exams (Can Pass)
                  </MDTypography>
                  <Select
                    isMulti
                    options={allCanPassExams}
                    value={courseData.canPassExam}
                    onChange={(selectedOptions) =>
                      updateCourseData("canPassExam", selectedOptions)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Support
                  </MDTypography>
                  <Select
                    isMulti
                    options={allSupports}
                    value={courseData.supports}
                    onChange={(selectedOptions) =>
                      updateCourseData("supports", selectedOptions)
                    }
                  />
                </Grid>
                {/* Copon */}
                <Grid item xs={12} sm={6}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Copon
                  </MDTypography>
                  <Select
                    isMulti
                    options={allCopons}
                    value={courseData.copon}
                    onChange={(selectedOptions) =>
                      updateCourseData("copon", selectedOptions)
                    }
                  />
                </Grid>
                {/* price */}
                <Grid item xs={12} sm={2}>
                  <FormField
                    type="number"
                    label="Price"
                    value={courseData.price}
                    onChange={(e) => updateCourseData("price", e.target.value)}
                  />
                </Grid>
                {/* duration */}
                <Grid item xs={12} sm={2}>
                  <FormField
                    type="number"
                    label="Duration (in hours)"
                    value={courseData.duration}
                    onChange={(e) =>
                      updateCourseData("duration", e.target.value)
                    }
                  />
                </Grid>

                {/* Sum of Site */}
                <Grid item xs={12} sm={2}>
                  <FormField
                    type="number"
                    label="Sum of Site"
                    value={courseData.sumOfSite}
                    onChange={(e) =>
                      updateCourseData("sumOfSite", e.target.value)
                    }
                  />
                </Grid>
                {/* Reserved Places */}
                <Grid item xs={12} sm={2}>
                  <FormField
                    type="number"
                    label="Reserved Places"
                    value={courseData.reservedPlaces}
                    onChange={(e) =>
                      updateCourseData("reservedPlaces", e.target.value)
                    }
                  />
                </Grid>
                {/* Lectures */}
                <Grid item xs={12} sm={2}>
                  <FormField
                    type="number"
                    label="Lectures"
                    value={courseData.lectures}
                    onChange={(e) =>
                      updateCourseData("lectures", e.target.value)
                    }
                  />
                </Grid>
                {/* objectifs */}
                <Grid item xs={12} sm={4}>
                  <Box mt={2}>
                    <Typography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Objectifs
                    </Typography>
                    <List>
                      {courseData.objectifs?.map((objectif, index) => (
                        <ListItem key={index} disablePadding>
                          <Chip
                            label={objectif}
                            onDelete={() =>
                              updateCourseData(
                                "objectifs",
                                courseData.objectifs.filter(
                                  (obj) => obj !== objectif,
                                ),
                              )
                            }
                            color="primary"
                            sx={{ margin: 0.8 }}
                          />
                        </ListItem>
                      ))}
                    </List>
                    <TextField
                      fullWidth
                      sx={{ margin: 1.2 }}
                      label="Add Objectifs"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && inputValue.trim() !== "") {
                          updateCourseData("objectifs", [
                            ...courseData?.objectifs,
                            inputValue.trim(),
                          ]);
                          setInputValue("");
                        }
                      }}
                      placeholder="Press Enter to add an objectif"
                      variant="outlined"
                      multiline
                      rows={3}
                    />
                  </Box>
                </Grid>
                {/* prerequisites */}
                <Grid item xs={12} sm={4}>
                  <Box mt={2}>
                    <Typography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Prerequisites
                    </Typography>
                    <List>
                      {courseData.prerequisites?.map((prerequisite, index) => (
                        <ListItem key={index} disablePadding>
                          <Chip
                            label={prerequisite}
                            onDelete={() =>
                              updateCourseData(
                                "prerequisites",
                                courseData.prerequisites.filter(
                                  (prereq) => prereq !== prerequisite,
                                ),
                              )
                            }
                            color="primary"
                            sx={{ margin: 0.8 }}
                          />
                        </ListItem>
                      ))}
                    </List>
                    <TextField
                      fullWidth
                      sx={{ margin: 1.2 }}
                      label="Add Prerequisites"
                      value={inputValue2}
                      onChange={(e) => setInputValue2(e.target.value)}
                      onKeyDown={(event) => {
                        if (
                          event.key === "Enter" &&
                          inputValue2.trim() !== ""
                        ) {
                          updateCourseData("prerequisites", [
                            ...(courseData.prerequisites || []),
                            inputValue2.trim(),
                          ]);
                          setInputValue2("");
                        }
                      }}
                      placeholder="Press Enter to add a prerequisite"
                      variant="outlined"
                      multiline
                      rows={3}
                    />
                  </Box>
                </Grid>

                {/* Is Active */}
                {/* In Slider */}
                <Grid item xs={12} sm={6}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    isActive
                  </MDTypography>
                  <Switch
                    checked={courseData.isActive}
                    onChange={(e) =>
                      updateCourseData("isActive", e.target.checked)
                    }
                    color="primary"
                  />
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    inSlider
                  </MDTypography>
                  <Switch
                    checked={courseData.inSlider}
                    onChange={(e) =>
                      updateCourseData("inSlider", e.target.checked)
                    }
                    color="primary"
                  />
                </Grid>

                {/* Image Upload */}
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <MDTypography
                      component="label"
                      variant="h5"
                      fontWeight="bold"
                      color="primary"
                      textAlign="center"
                      mb={2}
                    >
                      Course Image
                    </MDTypography>
                  </Grid>
                  {courseData.mainlmg && (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      mb={4}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "220px",
                          height: "auto",
                          borderRadius: "16px",
                          overflow: "hidden",
                          boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
                          transition:
                            "transform 0.3s ease, box-shadow 0.3s ease",
                          cursor: "pointer",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                          e.currentTarget.style.boxShadow =
                            "0 12px 20px rgba(0, 0, 0, 0.3)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.boxShadow =
                            "0 8px 15px rgba(0, 0, 0, 0.2)";
                        }}
                        onClick={() => {
                          document.getElementById("fileInput").click();
                        }}
                      >
                        <img
                          src={
                            typeof courseData.mainlmg === "string"
                              ? courseData.mainlmg
                              : URL.createObjectURL(courseData.mainlmg)
                          }
                          alt="Course"
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "16px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </Grid>
                  )}
                  <input
                    id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        const newImage = e.target.files[0];
                        setCourseData({ ...courseData, mainlmg: newImage });
                      }
                    }}
                  />
                  <MDBox
                    {...getRootProps()}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    border="2px dashed"
                    borderRadius="12px"
                    borderColor="grey.400"
                    bgcolor="grey.100"
                    py={4}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "grey.200",
                      },
                      transition: "background-color 0.2s ease-in-out",
                    }}
                  >
                    <input {...getInputProps()} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="textSecondary"
                      mb={1}
                      textAlign="center"
                    >
                      Drag & drop an image here or
                    </MDTypography>
                    <MDButton
                      variant="contained"
                      color="primary"
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      Browse Files
                    </MDButton>
                  </MDBox>
                </Grid>
                {/* <Grid item xs={12}>
                  <Grid>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      alignItems="center"
                      item
                      xs={12}
                    >
                      Course Image
                    </MDTypography>
                  </Grid>
                  {courseData.mainlmg && (
                    <img
                      src={
                        typeof courseData.mainlmg === "string"
                          ? courseData.mainlmg
                          : URL.createObjectURL(courseData.mainlmg)
                      }
                      alt="Course"
                      style={{
                        width: "200px",
                        height: "auto",
                        borderRadius: "8px",
                        marginBottom: "10px",
                      }}
                    />
                  )}
                  <MDBox
                    {...getRootProps()}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    border="2px dashed"
                    borderRadius="8px"
                    borderColor="grey.400"
                    bgcolor="grey.100"
                    py={4}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "grey.200",
                      },
                      transition: "background-color 0.2s ease-in-out",
                    }}
                  >
                    <input {...getInputProps()} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="textSecondary"
                      mb={1}
                    >
                      Drag & drop an image here or
                    </MDTypography>
                    <MDButton variant="contained" color="primary">
                      Browse Files
                    </MDButton>
                  </MDBox>
                </Grid> */}
              </Grid>
            </MDBox>
            <MDBox mt={4} display="flex" justifyContent="flex-end">
              <MDButton
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Update Course
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditCourse;
