/*
=========================================================
* Material Dashboard 2 PRO React - v2.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";

import { useEffect, useState } from "react";
import axios from "axios";

const  Analytics = () => {
  useEffect(() => {
    getInitialData()
  }, [])
  
  const [analyticsData, setAnalyticsData] = useState([])

  const getInitialData = async () => {
    try {
      const userId = sessionStorage.getItem("user")
      const userToken = JSON.parse(sessionStorage.getItem("token"))

      const statistiques = await axios.get(`${process.env.REACT_APP_API_URL}/api/V2/statistiques/mainStatistiques/${userId}/${userToken.iv}`, {
        headers: {
          Authorization: `Bearer ${userToken.content}`,
        },
      })
      setAnalyticsData(statistiques.data)
    }
    catch (err) {
      console.log(err)
    }
    finally {

    }
  }

  const { sales, tasks } = reportsLineChartData;

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>

        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="person"
                  title="Active users"
                  count={analyticsData.users}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+3%",
                  //   label: "than last month",
                  // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="auto_stories"
                  title="Active courses"
                  count={analyticsData.courses}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+55%",
                  //   label: "than lask week",
                  // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="rule"
                  title="Active exams"
                  count={analyticsData.exams}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+1%",
                  //   label: "than yesterday",
                  // }}
                />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Followers"
                  count="+91"
                  // percentage={{
                  //   color: "success",
                  //   amount: "",
                  //   label: "Just updated",
                  // }}
                />
              </MDBox>
            </Grid> */}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
