import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "@mui/material/Modal";

import moment from "moment";
import { Typography } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
import MDButton from "components/MDButton";
moment.locale("fr");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function VouchersPage() {
  const navigate = useNavigate();

  const [vouchers, setVouchers] = useState([]);
  const [openVocherModal, setOpenVocherModal] = useState(false);
  const [selectedVocher, setSelectedVocher] = useState(null);
  const [voucherFormData, setVoucherFormData] = useState({
    link: "",
  });

  const handleOpen = () => setOpenVocherModal(true);
  const handleClose = () => setOpenVocherModal(false);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/V2/voucher`,
      );
      setVouchers(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const updateVoucherFormData = async (field, value) => {
    setVoucherFormData((prev) => ({ ...prev, [field]: value }));
  };

  const deleteVoucher = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This voucher will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const UserID = sessionStorage.getItem("user");
        const Credentials = sessionStorage.getItem("token");
        const Token = JSON.parse(Credentials);

        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/V2/voucher/deleteOne/${UserID}/${Token.iv}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${Token.content}`,
              "Content-Type": "application/json",
            },
          },
        );
        Swal.fire("Deleted!", "The voucher has been deleted.", "success");

        getInitialData();
      }
    } catch (error) {
      console.error("Error deleting voucher:", error);
      Swal.fire(
        "Error!",
        "Failed to delete the voucher. Please try again.",
        "error",
      );
    }
  };

  const handleStatusChange = async (id, status) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `${status ? "Disabled" : "Enabled"}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        const UserID = sessionStorage.getItem("user");
        const Credentials = sessionStorage.getItem("token");
        const Token = JSON.parse(Credentials);

        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/v2/voucher/status/${UserID}/${Token.iv}/${status ? "false" : "true"}/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${Token.content}`,
              "Content-Type": "application/json",
            },
          },
        );

        Swal.fire("Updated!", `${status ? "Disabled" : "Enabled"}`, "success");
        getInitialData();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleValidChange = async (id, status) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `${status ? "This voucher will be invalid" : "Validate this voucher"}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        const UserID = sessionStorage.getItem("user");
        const Credentials = sessionStorage.getItem("token");
        const Token = JSON.parse(Credentials);

        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/v2/voucher/validation/${UserID}/${Token.iv}/${status ? "false" : "true"}/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${Token.content}`,
              "Content-Type": "application/json",
            },
          },
        );

        Swal.fire("Updated!", `${status ? "Invalid" : "Valide"}`, "success");
        getInitialData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editVoucher = (id) => {
    setSelectedVocher(id);
    handleOpen();
    // navigate(`/dashboard/vouchers/edit-voucher/${id}`); //${id}
  };

  const handleSubmitVoucherLink = async () => {
    try {
      const UserID = sessionStorage.getItem("user")
      const Credentials = sessionStorage.getItem("token")
      const Token = JSON.parse(Credentials)

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/voucher/updateVoucherLink/${UserID}/${Token.iv}/${selectedVocher}`,
        { link: voucherFormData.link },
        {
          headers: {
            Authorization: `Bearer ${Token.content}`,
            "Content-Type": "application/json"
          }
        }
      )

      Swal.fire({
        title: "Success!",
        text: "The voucher link has updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });

      getInitialData();
      handleClose()
    } catch (err) {
      console.log(err);
    }
  };

  const vouchersTableData = {
    columns: [
      { Header: "name", accessor: "name", align: "left" },
      { Header: "description", accessor: "description", align: "left" },
      { Header: "from exam", accessor: "fromExam", align: "left" },
      { Header: "from user", accessor: "forUser", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "validation", accessor: "validation", align: "center" },
      { Header: "Expired Date", accessor: "ExpiredDate", align: "center" },
      { Header: "Link", accessor: "Link", align: "center" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: vouchers.map((voucher) => ({
      name: voucher.name,
      description: voucher?.description,
      fromExam: voucher?.fromExam?.title,
      forUser: voucher?.forUser?.fullName,
      status: (
        <MDBadge
          variant="contained"
          color={voucher.isActive ? "success" : "error"}
          badgeContent={voucher.isActive ? "Active" : "Inactive"}
          onClick={() => handleStatusChange(voucher._id, voucher.isActive)}
          sx={{ cursor: "pointer" }}
        />
      ),
      validation: (
        <MDBadge
          variant="contained"
          color={voucher.isValid ? "success" : "error"}
          badgeContent={voucher.isValid ? "valid" : "Invalid"}
          onClick={() => handleValidChange(voucher._id, voucher.isValid)}
          sx={{ cursor: "pointer" }}
        />
      ),
      ExpiredDate: moment(voucher.expiredDate).format("DD MMM YYYY"),
      Link: voucher.link,
      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Edit voucher link">
            <IconButton color="info" onClick={() => editVoucher(voucher._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete voucher">
            <IconButton
              color="error"
              onClick={() => deleteVoucher(voucher._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <Modal
        open={openVocherModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter voucher link
          </Typography>
          <MDBox mb={2}>
            <FormField
              type="text"
              label="Link"
              value={voucherFormData.link}
              onChange={(e) => updateVoucherFormData("link", e.target.value)}
            />
          </MDBox>
          <MDButton
            variant="contained"
            color="primary"
            onClick={handleSubmitVoucherLink}
          >
            Submit
          </MDButton>
        </MDBox>
      </Modal>

      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Vouchers
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={vouchersTableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VouchersPage;
