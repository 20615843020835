import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ReactQuill from "react-quill";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function EditSolutionCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [SolutionCategoryData, setSolutionCategoryData] = useState({
    title: "",
    category: "",
    description: "",
    plainDescription: "",
    mainlmg: null,
  });

  const [currentImg, setCurrentImg] = useState(null);

  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});

  const handleDescriptionChange = (value, delta, source, editor) => {
    setSolutionCategoryData((prev) => ({
      ...prev,
      description: value, // Store HTML content
      plainDescription: editor.getText().trim(), // Store plain text content
    }));
  };

  const updateSolutionCategoryData = (field, value) => {
    setSolutionCategoryData((prev) => ({ ...prev, [field]: value }));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/category/categories`,
        );
        setCategories(categoryResponse.data.data || []);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSolutionCategoryData((prevState) => ({
        ...prevState,
        mainlmg: file,
      }));
    }
  };

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/solutionCategory/${id}`,
        );

        const data = response.data;

        setSolutionCategoryData({
          title: data.title || "",
          category: data.category || "",
          description: data.description || "",
          mainlmg: null,
        });

        setCurrentImg(data.mainlmg?.url || "");
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Failed to fetch category data.",
          icon: "error",
          confirmButtonText: "OK",
        });

        console.error("Error fetching category data:", error);
      }
    };

    fetchCategoryData();
  }, [id]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", SolutionCategoryData.title);
    // formData.append("category", SolutionCategoryData.category);
    formData.append("description", SolutionCategoryData.description);

    SolutionCategoryData.category.forEach((cat, index) => {
      formData.append(`category[${index}]`, cat);
    });

    if (SolutionCategoryData.mainlmg) {
      formData.append("mainlmg", SolutionCategoryData.mainlmg);
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/solutionCategory/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      Swal.fire({
        title: "Success!",
        text: "The category has been updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/6-solution-categories/solution-categories-page");
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to update the category.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit Category
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Update the information about this Category.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Category Details
                </MDTypography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="text"
                      label="Title"
                      value={SolutionCategoryData.title}
                      onChange={(e) =>
                        updateSolutionCategoryData("title", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Categories
                    </MDTypography>
                    {Array.isArray(categories) && (
                      <Select
                        options={categories.map((item) => ({
                          label: item.name,
                          value: item._id,
                        }))}
                        value={
                          SolutionCategoryData.category
                            ? categories
                                .filter((cat) =>
                                  SolutionCategoryData.category.includes(
                                    cat._id,
                                  ),
                                )
                                .map((cat) => ({
                                  label: cat.name,
                                  value: cat._id,
                                }))
                            : []
                        }
                        onChange={(selectedOptions) =>
                          updateSolutionCategoryData(
                            "category",
                            selectedOptions
                              ? selectedOptions.map((opt) => opt.value)
                              : [],
                          )
                        }
                        isMulti
                        placeholder="Select Categories"
                      />
                    )}
                    {errors.categories && (
                      <MDTypography color="error">
                        {errors.categories}
                      </MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {/* <FormField
                      type="text"
                      label="Description"
                      value={SolutionCategoryData.description}
                      onChange={(e) => updateSolutionCategoryData("description", e.target.value)}
                    /> */}
                    <ReactQuill
                      value={SolutionCategoryData.description}
                      onChange={handleDescriptionChange}
                    />
                  </Grid>
                </Grid>
                <MDBox mt={4}>
                  <MDTypography
                    variant="subtitle2"
                    fontWeight="light"
                    color="textSecondary"
                    mb={1}
                  >
                    Main Image
                  </MDTypography>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    border="2px dashed"
                    borderColor="grey.400"
                    bgcolor="grey.100"
                    borderRadius="8px"
                    py={4}
                  >
                    <MDButton
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={() =>
                        document.getElementById("file-input").click()
                      }
                    >
                      Browse Files
                    </MDButton>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="file-input"
                    />
                    {currentImg && !SolutionCategoryData.mainlmg && (
                      <img
                        src={currentImg}
                        alt="Current Category Image"
                        style={{
                          width: "100%",
                          maxWidth: "150px",
                          marginTop: "10px",
                          borderRadius: "8px",
                        }}
                      />
                    )}
                    {SolutionCategoryData.mainlmg && (
                      <img
                        src={URL.createObjectURL(SolutionCategoryData.mainlmg)}
                        alt="Updated Category Image"
                        style={{
                          width: "100%",
                          maxWidth: "150px",
                          marginTop: "10px",
                          borderRadius: "8px",
                        }}
                      />
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Save Changes
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditSolutionCategory;
