import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Select from "react-select";

function EditUser() {
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    phone: "",
    type: "",
    description: "",
    isActive: false,
    isTeacher: false,
    techSkills: 0,
    teachSkills: 0,
    experience: 0,
    schoolLevel: "none",
    specialization: [],
    canPassExam: [],
    supports: [],
    mainImage: null,
  });

  const [errors, setErrors] = useState({});
  const [allExams, setAllExams] = useState([]);
  const [allSupports, setAllSupports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/V2/user/users/${id}`);
        const user = userResponse.data;
  
        setUserData({
          ...user,
          courses: user.courses.map((course) => ({
            value: course._id,
            label: course.title,
          })),
          exams: user.exams.map((exam) => ({
            value: exam._id,
            label: exam.title,
          })),
        });
  
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        Swal.fire("Error", "Failed to fetch data. Please try again.", "error");
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [id]);
  
  
  

  const handleInputChange = (field, value) => {
    setUserData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  

  const validateForm = () => {
    const newErrors = {};
    if (!userData.fullName) newErrors.fullName = "Full Name is required.";
    if (!userData.email) newErrors.email = "Email is required.";
    if (!userData.phone) newErrors.phone = "Phone number is required.";
    if (!userData.type) newErrors.type = "User type is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      Swal.fire("Validation Error", "Please fix the errors before submitting.", "error");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("fullName", userData.fullName);
      formData.append("email", userData.email);
      formData.append("phone", userData.phone);
      formData.append("type", userData.type);
      formData.append("description", userData.description);
      formData.append("isActive", userData.isActive);
      formData.append("isTeacher", userData.isTeacher);
      formData.append("techSkills", userData.techSkills);
      formData.append("teachSkills", userData.teachSkills);
      formData.append("experience", userData.experience);
      formData.append("schoolLevel", userData.schoolLevel);
      formData.append("specialization", JSON.stringify(userData.specialization));
      formData.append("canPassExam", JSON.stringify(userData.canPassExam));
      formData.append("supports", JSON.stringify(userData.supports));
      if (userData.mainImage) {
        formData.append("mainImage", userData.mainImage);
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/user/users/${id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "User updated successfully.",
          icon: "success",
        }).then(() => navigate("/dashboard/usersList/usersList-page"));
      }
    } catch (error) {
      console.error("Error updating user:", error);
      Swal.fire("Error", "Failed to update user. Please try again.", "error");
    }
  };

  if (isLoading) {
    return (
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h6">Loading...</MDTypography>
      </MDBox>
    );
  }

  function FormField({ label, ...rest }) {
    return <MDInput {...rest} label={label} variant="standard" fullWidth />;
  }

  FormField.propTypes = {
    label: PropTypes.string.isRequired,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit User
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Modify the details of the user below.
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3}>
            <Grid container spacing={3}>
              {/* Full Name */}
              <Grid item xs={12} md={6}>
                <FormField
                  label="Full Name"
                  value={userData.fullName}
                  onChange={(e) => handleInputChange("fullName", e.target.value)}
                  error={!!errors.fullName}
                  helperText={errors.fullName}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} md={6}>
                <FormField
                  label="Email"
                  type="email"
                  value={userData.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>

              {/* Phone */}
              <Grid item xs={12} md={6}>
                <FormField
                  label="Phone"
                  value={userData.phone}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </Grid>

              {/* Type */}
              <Grid item xs={12} md={6}>
                <FormField
                  label="Type"
                  value={userData.type}
                  onChange={(e) => handleInputChange("type", e.target.value)}
                  error={!!errors.type}
                  helperText={errors.type}
                />
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <FormField
                  label="Description"
                  value={userData.description}
                  onChange={(e) => handleInputChange("description", e.target.value)}
                />
              </Grid>

              {/* Tech Skills */}
              <Grid item xs={12} md={6}>
                <FormField
                  label="Tech Skills"
                  value={userData.techSkills}
                  onChange={(e) => handleInputChange("techSkills", e.target.value)}
                />
              </Grid>

              {/* Teach Skills */}
              <Grid item xs={12} md={6}>
                <FormField
                  label="Teach Skills"
                  value={userData.teachSkills}
                  onChange={(e) => handleInputChange("teachSkills", e.target.value)}
                />
              </Grid>

              {/* Experience */}
              <Grid item xs={12} md={6}>
                <FormField
                  label="Experience"
                  value={userData.experience}
                  onChange={(e) => handleInputChange("experience", e.target.value)}
                />
              </Grid>

              {/* School Level */}
              <Grid item xs={12} md={6}>
                <FormField
                  label="School Level"
                  value={userData.schoolLevel}
                  onChange={(e) => handleInputChange("schoolLevel", e.target.value)}
                />
              </Grid>

              {/* Specialization */}
              {/* <Grid item xs={12} md={6}>
                <Select
                  isMulti
                  options={allSupports || []}
                  value={userData.specialization || []}
                  onChange={(selectedOptions) => handleInputChange("specialization", selectedOptions)}
                />
              </Grid> */}

              {/* Can Pass Exam */}
              <Grid item xs={12} md={6}>
  <MDTypography component="label" variant="button" fontWeight="regular">
    Exams (Can Pass)
  </MDTypography>
  <Select
    isMulti
    options={userData.exams || []}
    value={userData.exams || []}
    onChange={(selectedOptions) => handleInputChange("exams", selectedOptions)}
  />
</Grid>

              <Grid item xs={12} md={6}>
  <MDTypography component="label" variant="button" fontWeight="regular">
    Courses
  </MDTypography>
  <Select
    isMulti
    options={userData.courses || []}
    value={userData.courses || []}
    onChange={(selectedOptions) => handleInputChange("courses", selectedOptions)}
  />
</Grid>


              {/* Upload Image */}
              <Grid item xs={12}>
                <MDTypography component="label" variant="button">
                  Upload Image
                </MDTypography>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  border="2px dashed"
                  borderColor="grey.400"
                  bgcolor="grey.100"
                  borderRadius="8px"
                  py={4}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "grey.200",
                    },
                    transition: "background-color 0.2s ease-in-out",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        const file = e.target.files[0];
                        if (file.type.startsWith("image/")) {
                          handleInputChange("mainImage", file);
                        } else {
                          Swal.fire("Invalid file", "Please upload an image file.", "error");
                        }
                      }
                    }}
                    style={{ display: "none" }}
                    id="file-input"
                  />

                  {userData.mainImage && (
                    <img
                      src={URL.createObjectURL(userData.mainImage)}
                      alt="Uploaded File"
                      style={{
                        width: "100%",
                        maxWidth: "150px",
                        marginTop: "10px",
                        borderRadius: "8px",
                      }}
                    />
                  )}

                  <MDButton
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => document.getElementById("file-input").click()}
                    sx={{ mt: userData.mainImage ? 2 : 0 }}
                  >
                    Browse Files
                  </MDButton>
                </MDBox>
                <MDTypography variant="subtitle2" mt={2}>
                  {userData.mainImage ? "File Uploaded Successfully" : "No file uploaded yet."}
                </MDTypography>
              </Grid>

              {/* Is Active */}
              <Grid item xs={12} md={6}>
                <MDTypography component="label" variant="button">
                  Is Active
                </MDTypography>
                <Switch
                  checked={userData.isActive}
                  onChange={(e) => handleInputChange("isActive", e.target.checked)}
                  color="primary"
                />
              </Grid>

              {/* Is Teacher */}
              <Grid item xs={12} md={6}>
                <MDTypography component="label" variant="button">
                  Is Teacher
                </MDTypography>
                <Switch
                  checked={userData.isTeacher}
                  onChange={(e) => handleInputChange("isTeacher", e.target.checked)}
                  color="primary"
                />
              </Grid>
            </Grid>

            <MDBox mt={4} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Update User
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditUser;
