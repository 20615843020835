import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { useDropzone } from "react-dropzone";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function EditTechnology() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      updateTechnologyData("mainlmg", acceptedFiles[0]);
    },
    accept: "image/*",
  });

  const [technologyData, setTechnologyData] = useState({
    name: "",
    description: "",
    plainDescription: "",
    mainlmg: null
  });

  const handleImageClick = () => {
    document.getElementById("file-input").click();
  };

  const updateTechnologyData = (field, value) => {
    setTechnologyData((prev) => ({ ...prev, [field]: value }));
  };

  const handleDescriptionChange = (value, delta, source, editor) => {
    setTechnologyData((prev) => ({
      ...prev,
      description: value, // Keep HTML
      plainDescription: editor.getText().trim(), // Extract plain text
    }));
  };

  useEffect(() => {
    const fetchTechnologyData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V2/technology/technologies/${id}`
        );
        setTechnologyData(response.data.data || { name: "", description: "", mainlmg: null });
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "Failed to fetch technology data.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Error fetching technology:", error);
      }
    };

    fetchTechnologyData();
  }, [id]);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      updateTechnologyData("mainlmg", e.target.files[0]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData()
      const technologiesData = {
        name: technologyData.name,
        description: technologyData.description,
        mainlmg: technologyData.mainlmg
      }

      for (const [key, value] of Object.entries(technologiesData)) {
        formData.append(key, value);
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/technology/technologies/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        },
      );

      Swal.fire({
        title: "Success!",
        text: "The technology has been updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/dashboard/7-technologies/technologies-page");
      console.log("Response:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to update the technology.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error.response.data);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit Technology
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Update the information about this Technology.
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Technology Details
                </MDTypography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormField
                      type="text"
                      label="Name"
                      value={technologyData.name}
                      onChange={(e) => updateTechnologyData("name", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ReactQuill
                      value={technologyData.description}
                      onChange={handleDescriptionChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {/* IMAGE upload */}
                    <MDBox>
                      <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                        Media
                      </MDTypography>

                      <MDBox mt={3}>
                        <MDBox mb={1} ml={0.5}>
                          <MDTypography
                            component="label"
                            variant="subtitle1"
                            fontWeight="light"
                            color="textSecondary"
                          >
                            Main Image
                          </MDTypography>
                        </MDBox>

                        {!technologyData.mainlmg && (
                          <MDBox
                            {...getRootProps()}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            border="2px dashed"
                            borderRadius="8px"
                            borderColor="grey.400"
                            bgcolor="grey.100"
                            py={4}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "grey.200",
                              },
                              transition: "background-color 0.2s ease-in-out",
                            }}
                          >
                            <input {...getInputProps()} />
                            <MDTypography variant="button" fontWeight="regular" color="textSecondary" mb={1}>
                              Drag & drop an image here, or
                            </MDTypography>
                            <MDButton variant="contained" color="primary" size="medium">
                              Browse Files
                            </MDButton>
                          </MDBox>
                        )}

                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          id="file-input"
                        />

                        {technologyData.mainlmg && (
                          <MDBox
                            mt={3}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            textAlign="center"
                            onClick={handleImageClick}
                          >
                            {/* <p>{`${process.env.REACT_APP_API_URL}/${technologyData.mainlmg.path}`}</p> */}
                            <img
                              src={technologyData.mainlmg instanceof File ? URL.createObjectURL(technologyData.mainlmg) : `${process.env.REACT_APP_API_URL}/${technologyData.mainlmg.path}`}
                              alt="Exam Main Image"
                              style={{
                                width: "100%",
                                maxWidth: "150px",
                                marginTop: "10px",
                                borderRadius: "8px",
                                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                cursor: "pointer",
                              }}
                            />
                            <MDTypography variant="body2" color="textSecondary" mt={2}>
                              Click to upload a new image
                            </MDTypography>
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                  </Grid>

                </Grid>
              </MDBox>
            </Card>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDButton variant="contained" color="primary" onClick={handleSubmit}>
                Save Change
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditTechnology;
