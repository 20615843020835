import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Swal from "sweetalert2";

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function AskedCoursesPage() {
  const navigate = useNavigate();

  const [askedCourses, setAskedCourses] = useState([]);
  const [oneAskedCourse, setOneAskedCourse] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Fetching promotions data from the API
  useEffect(() => {
    getInitialData()
  }, []);

  const getInitialData = async () => {
    try {
      const askedCoursesRes = await axios.get(`${process.env.REACT_APP_API_URL}/api/V2/askForCourse/filledForms`)
      setAskedCourses(askedCoursesRes.data.forms)
    }
    catch (err) {
      console.log(err)
    }
  }

  const getOneAskedCourseDetails = async (askedCourse) => {
    try {
      setOpen(true)
      setOneAskedCourse(askedCourse)

      console.log("askedCourse", askedCourse)
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleDelete = async (id) => {
    try {
      // Show SweetAlert2 confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This request will be permanently deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // Proceed with the deletion
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/askForCourse/deleteOne/${id}`);

        // Update the local state to remove the deleted exam
        getInitialData()

        // Show success alert
        Swal.fire("Deleted!", "The request has been deleted.", "success");
      }
    } catch (error) {
      // Show error alert
      Swal.fire("Error!", "Failed to delete the exam. Please try later.", "error");
    }
  }

  // Function to delete a promotion from the database and the state
  // const deleteOnePromotion = async (id) => {
  //   try {
  //     // Show SweetAlert2 confirmation dialog
  //     const result = await Swal.fire({
  //       title: "Are you sure?",
  //       text: "This promotion will be permanently deleted!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     });

  //     if (result.isConfirmed) {
  //       // Proceed with the deletion
  //       await axios.delete(`${process.env.REACT_APP_API_URL}/api/V2/promotion/all-promotions/${id}`);

  //       // Update the local state to remove the deleted promotion
  //       setPromotions((prev) => prev.filter((promotion) => promotion._id !== id));

  //       // Show success alert
  //       Swal.fire("Deleted!", "The promotion has been deleted.", "success");
  //     }
  //   } catch (error) {
  //     console.error("There was an error deleting the promotion!", error);

  //     // Show error alert
  //     Swal.fire("Error!", "Failed to delete the promotion. Please try again.", "error");
  //   }
  // };

  // Table data for displaying the promotions
  const askedCoursesTableData = {
    columns: [
      { Header: "FullName", accessor: "fullName", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Phone", accessor: "phone", align: "center" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],
    rows: askedCourses?.map((el) => ({
      fullName: el.fullName,
      email: el.email,
      phone: el.phone,

      actions: (
        <MDBox display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Details">
            <IconButton color="default" onClick={() => getOneAskedCourseDetails(el)}>
              <InfoIcon />
            </IconButton>
          </Tooltip>

                    <Tooltip title="Delete User">
                      <IconButton color="error" onClick={() => handleDelete(el._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
        </MDBox>
      ),
    })),
  };

  return (
    <DashboardLayout>

    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {oneAskedCourse?.fullName}
          </Typography>
          <Typography id="modal-modal-description" lg={{ mt: 2 }}>
            <ul>
              <li style={{ display: "grid", gridTemplateColumns: "1fr 0.2fr 1fr" }} > <span>Email</span> <span>:</span> <span>{oneAskedCourse?.email}</span> </li>
              <li style={{ display: "grid", gridTemplateColumns: "1fr 0.2fr 1fr" }} > <span>Phone</span> <span>:</span> <span>{oneAskedCourse?.phone}</span> </li>
              <li style={{ display: "grid", gridTemplateColumns: "1fr 0.2fr 1fr" }} > <span>Preferred Contact</span> <span>:</span> <span>{oneAskedCourse?.preferredContact}</span> </li>
              <li style={{ display: "grid", gridTemplateColumns: "1fr 0.2fr 1fr" }} > <span>Remote Or Onsite</span> <span>:</span> <span>{oneAskedCourse?.remoteOrOnsite}</span> </li>
              <li style={{ display: "grid", gridTemplateColumns: "1fr 0.2fr 1fr" }} > <span>Student Or Enterprise</span> <span>:</span> <span>{oneAskedCourse?.studentOrEnterprise}</span> </li>
              <li style={{ display: "grid", gridTemplateColumns: "1fr 0.2fr 1fr" }} > <span>Number Of Students</span> <span>:</span> <span>{oneAskedCourse?.numberOfStudents}</span> </li>
              <li style={{ display: "grid", gridTemplateColumns: "1fr 0.2fr 1fr" }} > <span>Technology</span> <span>:</span> <span>{oneAskedCourse?.chosenTech?.name}</span> </li>
              <li style={{ display: "grid", gridTemplateColumns: "1fr 0.2fr 1fr" }} > <span>module</span> <span>:</span> <span>{oneAskedCourse?.module}</span> </li>
              <li style={{ display: "grid", gridTemplateColumns: "1fr 0.2fr 1fr" }} > <span>Certification Needed</span> <span>:</span> <span>{oneAskedCourse?.certificationNeeded ? "Yes" : "No"}</span> </li>
            </ul>
          </Typography>
        </MDBox>
      </Modal>

      <DashboardNavbar />
      <MDBox
        mx={2}
        mt={-1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All asked courses
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={askedCoursesTableData}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  isSorted={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AskedCoursesPage;
